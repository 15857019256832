import PrimaryButton from "@/components/Button/PrimaryButton";
import Divider from "@/components/Divider";
import Input from "@/components/Input/Input";
import Logo from "@/assets/images/logo.png";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { year } from "@/constants/app";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { NewsLetterSchema, TNewsLetter } from "@/types/validations/newsletter";

const Footer = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<TNewsLetter>({
    mode: "onBlur",
    resolver: zodResolver(NewsLetterSchema),
    reValidateMode: "onChange",
  });

  const onSubmit: SubmitHandler<TNewsLetter> = async (data) => {
    console.log(data);
  };

  return (
    <div className="flex flex-col items-center mt-10">
      <div className="width-container">
        <div className="flex flex-col md:flex-row md:items-center justify-between mt-2 pb-2">
          <div className="">
            <h2 className="text-gray-900 text-2xl md:text-[20px] font-bold md:ma-w-[803px]">
              Join our newsletter
            </h2>
            <p className="text-[#475467] text-sm md:text-base md:max-w-[803px] mt-3">
              We’ll send you a nice letter once per week. No spam.
            </p>
          </div>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col md:flex-row md:items-center gap-4 w-full md:w-[397px]"
          >
            <Input
              placeholder="Enter your email"
              {...register("email")}
              error={errors.email?.message}
            />
            <PrimaryButton
              variant="primary"
              text="Subscribe"
              className="w-[101px]"
            />
          </form>
        </div>
        <Divider />
        <div className="flex flex-row items-center justify-between mt-2 pb-2">
          <div className="w-[48px] h-[48px]">
            <img src={Logo} alt="logo" className="w-full" />
          </div>
          <div className="gap-[24px] flex items-center">
            <Socials
              link="https://www.facebook.com/share/19wc3ZWrcZ/?mibextid=wwXIfr"
              icon={<FaFacebookF className="text-base text-brand-600" />}
            />
            <Socials
              link="https://www.linkedin.com/in/point2delivery-service-87b99a345?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
              icon={<FaLinkedinIn className="text-base text-brand-600" />}
            />
            <Socials
              link="https://x.com/point2delivery?s=11"
              icon={<FaTwitter className="text-base text-brand-600" />}
            />
            <Socials
              link="https://www.instagram.com/point2_delivery?igsh=a3h4ZHBjOWFra3Az&utm_source=qr"
              icon={<FaInstagram className="text-base text-brand-600" />}
            />
          </div>
        </div>
        <Divider />
        <div className="flex flex-col md:flex-row md:items-center justify-between mt-2 pb-2">
          <div>
            <div className="flex flex-col md:flex-row gap-3">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.google.com/maps?q=${encodeURIComponent(
                  "26 Kayode Street, Ogba, Lagos, Nigeria"
                )}`}
                className="text-gray-600 font-medium text-sm"
              >
                26 Kayode Street, Ogba, Lagos State, Nigeria
              </a>
              <a
                className="text-gray-600 font-medium text-sm"
                href="tel:02018891769"
              >
                02018891769
              </a>
              <a
                className="text-brand-600 text-sm font-medium"
                href="mailto:support@Point2.ng"
              >
                support@Point2.ng
              </a>
            </div>
            <div className="flex flex-col md:flex-row md:items-center gap-6 mt-5">
              <Link to="/" className="text-gray-600 font-medium text-sm">
                Terms
              </Link>
              <Link to="/" className="text-gray-600 font-medium text-sm">
                Privacy
              </Link>
              <Link to="/" className="text-gray-600 font-medium text-sm">
                Cookies
              </Link>
              <Link to="/" className="text-gray-600 font-medium text-sm">
                FAQ
              </Link>
            </div>
          </div>
          <p className="text-[#667085] text-sm mt-8 md:mt-0 mb-8">
            © {year} Point2 Logistics. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

const Socials = ({ icon, link }: { icon: React.ReactNode; link: string }) => (
  <a
    href={link}
    target="_blank"
    className="w-10 h-10 bg-brand-25 rounded-full flex justify-center items-center"
  >
    {icon}
  </a>
);

export default Footer;
