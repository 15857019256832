import SendPackage from "@/components/send-package";
import Ads from "@/components/ads";
import Notification from "./components/notifications";
import Seo from "@/components/Seo";

const Notifications = () => {
  return (
    <div className="flex w-full min-hscreen">
      <Seo title="Point2 | Notifications" />
      <div className="border-r border-r-gray-200 min-835:w-[70%] w-full py-5 overflow-y-scroll h-screen px-8">
        <Notification />
      </div>
      <div className="w-[30%] wfull min-hscreen sticky top-0 left-0 min-835:block hidden">
        <div className="px-4 h-screen overflow-y-scroll overflow-hidden">
          <SendPackage />
          <Ads />
        </div>
      </div>
    </div>
  );
};

export default Notifications;
