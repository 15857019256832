import cursorHover from "@/assets/svgs/cursor-hover.svg";
import { useScreenContext } from "../context/screenContext";
import Box from "@/assets/svgs/box.svg";
import { GoDotFill } from "react-icons/go";
import { FaRegCircleCheck } from "react-icons/fa6";
import PrimaryButton from "@/components/Button/PrimaryButton";
import { FaTimes } from "react-icons/fa";
import { useGetOrders } from "@/services/order";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Spinner from "@/components/Spinner";
import { amountFormat, getStatus, getStatusColor } from "@/utils";

const OrderDetails = () => {
  const { orderId, setOrderId } = useScreenContext();

  const { getOrder, orderPayment } = useGetOrders();

  const { data, isLoading } = useQuery({
    queryKey: ["get_order", orderId],
    queryFn: () => getOrder(orderId),
    enabled: !!orderId,
  });

  const queryClient = useQueryClient();

  const BASE_URL = process.env.REACT_APP_WEBSITE_URL;

  const { mutate: paymentFn, isPending } = useMutation({
    mutationFn: orderPayment,
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ["get_orders"] });
      const redirectUrl = response?.authorization_url;
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    },
  });

  return (
    <div className="relative">
      {isLoading && <Spinner />}
      {!data && (
        <div className="flex flex-col items-center justify-center h-screen">
          <img src={cursorHover} alt="empty image" />
          <p className="text-gray-700 text-sm font-medium">
            Select a Package to display here
          </p>
        </div>
      )}

      {data && (
        <div className="">
          <div className="flex gap-3">
            <div className="bg-brand-25 flex items-center justify-center w-[42px] h-[42px] rounded-full">
              <img src={Box} alt="box" />
            </div>
            <div className="flex flex-col gap-2">
              <p className="text-gray-700 font-bold text-sm">
                {data?.package_name}
              </p>
              <p className="text-gray-800 text-sm font-normal">
                Tracking ID: {data?.tracking_id ?? "Unavailable"}
              </p>
            </div>
          </div>
          <div className="mt-8">
            <div className="flex flex-col space-y-3">
              <div className="flex justify-between items-center">
                <div className="flex-1">
                  <p className="text-gray-600 text-xs font-medium">
                    Recipient Name
                  </p>
                  <p className="text-gray-700 font-bold text-sm">
                    {data?.recepient_name}
                  </p>
                </div>
                <div className="flex-1">
                  <p className="text-gray-600 text-xs font-medium">
                    Rider’s Name
                  </p>
                  <p className="text-gray-700 font-bold text-sm">
                    {data?.rider.first_name ?? "Unavailable"}{" "}
                    {data?.rider?.last_name}
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div className="flex-1">
                  <p className="text-gray-600 text-xs font-medium">
                    Delivery type
                  </p>
                  <p className="text-gray-700 font-bold text-sm">
                    {data?.delivery_type === "STANDARD_DELIVERY"
                      ? "Standard"
                      : data?.delivery_type === "EXPRESS_DELIVERY"
                      ? "Express"
                      : ""}
                  </p>
                </div>
                <div className="flex-1">
                  <p className="text-gray-600 text-xs font-medium">
                    Amount Payable (N)
                  </p>
                  <p className="text-gray-700 font-bold text-sm">
                    {amountFormat(data?.amount.toString())}
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div className="flex-1">
                  <p className="text-gray-600 text-xs font-medium">
                    Order Time and date
                  </p>
                  <p className="text-gray-700 font-bold text-sm">
                    {new Date(data?.created_at).toLocaleDateString()}{" "}
                    {new Date(data?.created_at).toLocaleTimeString()}
                  </p>
                </div>
              </div>
            </div>

            <div className="bg-gray-50 mt-5 p-4 rounded-2xl">
              <div className="flex-1 flex gap-1">
                <GoDotFill className="text-brand-100" size={20} />
                <div>
                  <p className="text-gray-600 text-xs font-medium">From</p>
                  <p className="text-gray-700 text-sm font-medium">
                    {data?.pickup_location}
                  </p>
                </div>
              </div>
              <div className="flex-1 flex gap-1 mt-5">
                <GoDotFill className="text-green-primary" size={20} />
                <div>
                  <p className="text-gray-600 text-xs font-medium">
                    Shipped to
                  </p>
                  <p className="text-greay-700 text-sm font-medium">
                    {data?.delivery_point_location}
                  </p>
                </div>
              </div>
              <div className="flex-1 flex items-center gap-1 mt-8 ml-3">
                <p className="text-gray-600 font-bold text-sm">
                  Status: {getStatus(data?.status)}
                </p>
                <FaRegCircleCheck color={getStatusColor(data?.status)} />
              </div>
            </div>

            <div className="mt-7 space-y-6">
              <div className="">
                <p className="text-gray-700 font-bold text-base">Who to Pay</p>
                <p className="text-gray-600 text-sm">{data?.payee}</p>
              </div>
              <div className="">
                <p className="text-gray-700 font-bold text-base">
                  Payment Status
                </p>
                <p
                  className={`${
                    data?.payment_status === "PENDING"
                      ? "text-orange-primary"
                      : data?.payment_status === "APPROVED"
                      ? "text-[#32d583]"
                      : "text-gray-600"
                  } text-sm`}
                >
                  {data?.payment_status ?? "Status not available"}
                </p>
              </div>
            </div>

            {(data?.status === "INTRANSIT" || data?.status === "DELIVERED") && (
              <div className="mt-7 space-y-6">
                <PrimaryButton
                  disabled={data?.payment_status === "APPROVED"}
                  isLoading={isPending}
                  text={
                    data?.payment_status === "APPROVED" ? "Paid" : "Pay Now"
                  }
                  variant="primary"
                  className="w-full"
                  onClick={() =>
                    paymentFn({
                      callback_url: BASE_URL ?? "",
                      order_id: data?.id,
                    })
                  }
                />
              </div>
            )}
          </div>

          <PrimaryButton
            text="Close"
            variant="secondary"
            className="bg-gray-50 w-full text-center text-gray-700 mt-[30%]"
            style={{ color: "#344054" }}
            postIcon={<FaTimes />}
            onClick={() => setOrderId("")}
          />
        </div>
      )}
    </div>
  );
};

export default OrderDetails;
