import Ads from "@/components/ads";
import SendPackage from "@/components/send-package";
import InvoiceDetails from "./component/invoice-details";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/stores/store";
import { useNavigate } from "react-router-dom";

const Invoice = () => {
  const { user } = useSelector((state: RootState) => state?.user);
  const userObj = user?.user_data || user?.data;
  const navigate = useNavigate();

  useEffect(() => {
    if (userObj?.type === "USER") {
      navigate(-1);
    }
  }, [userObj]);

  return (
    <div className="flex w-full min-hscreen">
      <div className="border-r border-r-gray-200 w-[70%] py-5 overflow-y-scroll h-screen px-8">
        <InvoiceDetails />
      </div>
      <div className="w-[30%] min-hscreen sticky top-0 left-0">
        <div className="px-4 h-screen overflow-y-scroll overflow-hidden">
          <SendPackage />
          <Ads />
        </div>
      </div>
    </div>
  );
};

export default Invoice;
