import PrimaryButton from "@/components/Button/PrimaryButton";
import { APP_ROUTES } from "@/constants/app-routes";
import { BulkOrder } from "@/models/Order";
import { useGetOrders } from "@/services/order";
import { amountFormat, formattedDate, getStatusColor } from "@/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

const TableData = ({ orders }: { orders: BulkOrder }) => {
  const navigate = useNavigate();
  const { ordersPayment } = useGetOrders();

  const handleView = (id: string) => {
    navigate(APP_ROUTES.ACTIVITY, { state: { bulk_order_id: id } });
  };

  const queryClient = useQueryClient();

  const BASE_URL = process.env.REACT_APP_WEBSITE_URL;

  const { mutate: paymentFn, isPending } = useMutation({
    mutationFn: ordersPayment,
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ["get_orders"] });
      const redirectUrl = response?.authorization_url;
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    },
  });

  return (
    <tr className="font-light">
      <td className="capitalize">{orders?.id}</td>
      <td className="capitalize">{orders?.user_name}</td>
      <td className="capitalize">{orders?.user_email}</td>
      <td className="capitalize">
        {amountFormat(orders?.orders_total_amount.toString())}
      </td>
      <td className="capitalize">
        {amountFormat(orders?.amount_left_to_pay.toString())}
      </td>
      <td
        className="capitalize"
        style={{ color: getStatusColor(orders?.status.toUpperCase()) }}
      >
        {orders?.status}
      </td>
      <td className="capitalize">{formattedDate(orders?.due_date)}</td>
      <td className="capitalize">{formattedDate(orders?.created_at)}</td>
      <td className="flex flex-row gap-1">
        <PrimaryButton
          variant="primary"
          text="View"
          size="sm"
          onClick={() => handleView(orders?.id)}
        />
        <PrimaryButton
          disabled={orders?.status === "paid"}
          isLoading={isPending}
          variant="secondary"
          className="bg-black"
          text={orders?.status === "paid" ? "Paid" : "Pay"}
          size="sm"
          onClick={() =>
            paymentFn({
              bulk_order_id: orders?.id,
              callback_url: BASE_URL ?? "",
            })
          }
        />
      </td>
    </tr>
  );
};

export default TableData;
