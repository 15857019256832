import PrimaryButton from "@/components/Button/PrimaryButton";
import Seo from "@/components/Seo";
import { APP_ROUTES } from "@/constants/app-routes";
import { setIsAuthentication } from "@/stores/reducers/user-reducers";
import { FaCircleCheck } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const Welcome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const goToDashboard = () => {
    dispatch(setIsAuthentication(true));
    navigate(APP_ROUTES.DASHBOARD);
  };

  return (
    <section className="w-full max-w-[488px]">
      <Seo title="Point2 | Welcome" />
      <h1 className="text-gray-700 text-[30px] font-medium">
        Welcome {state},
      </h1>
      <p className="text-gray-900 text-sm font-medium mt-1">
        Welcome onboard, you can start sending packages with our trusted riders
      </p>
      <div className="flex flex-col items-center justify-center mt-20">
        <div className="w-[128px] h-[128px] rounded-full bg-[#CFF7D3] flex flex-col justify-center items-center">
          <FaCircleCheck className="" size={90} color="#14AE5C" />
        </div>
      </div>
      <div className="mt-12">
        <PrimaryButton
          text="Go to Dashboard"
          variant="primary"
          className="w-full"
          onClick={goToDashboard}
        />
      </div>
    </section>
  );
};

export default Welcome;
