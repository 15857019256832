export const APP_ROUTES = {
  HOME: "/",
  ABOUT: "/about",
  LOGIN: "/login",
  CREATE_ACCOUNT: "/sign-up",
  CREATE_ACCOUNT2: "/sign-up/complete",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  VERIFY_OTP: "/verify-otp",
  PASSWORD_RESET_SUCCESS: "/password-reset-success",
  WELCOME: "/welcome",
  TRACK: "/track",
  DASHBOARD: "/dashboard",
  REQUEST_RIDER: "/request-rider",
  ACTIVITY: "/activity",
  NOTIFICATIONS: "/notifications",
  PROFILE: "/profile",
  MORE: "/more",
  INVOICE: "/more/invoice",
  BULK_ORDER: "/bulk-order",
  ORDER_DETAILS: "/order-details"
};
