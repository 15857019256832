import { APP_ROUTES } from "@/constants/app-routes";
import { removeToken } from "@/helpers/authHelpers";
import {
  clearUser,
  setIsAuthentication,
} from "@/stores/reducers/user-reducers";
import { RootState } from "@/stores/store";
import { FiLogOut } from "react-icons/fi";
import { LiaTimesSolid } from "react-icons/lia";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import Avi from "@/assets/images/profileImage.png";

const DashboardMobileNav = ({ toggle }: { toggle: () => void }) => {
  const { user } = useSelector((state: RootState) => state?.user);
  const userObj = user?.user_data || user?.data;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const menu: { title: string; path: string }[] = [
    {
      title: "Home",
      path: APP_ROUTES.DASHBOARD,
    },
    {
      title: "Request Rider",
      path: APP_ROUTES.REQUEST_RIDER,
    },
    {
      title: "Activity",
      path: APP_ROUTES.ACTIVITY,
    },
    {
      title: "Bulk Orders",
      path: APP_ROUTES.BULK_ORDER,
    },
    {
      title: "Notification",
      path: APP_ROUTES.NOTIFICATIONS,
    },
    userObj?.type === "USER"
      ? {
          title: "My Profile",
          path: APP_ROUTES.PROFILE,
        }
      : {
          title: "More",
          path: APP_ROUTES.MORE,
        },
  ];

  const logout = () => {
    dispatch(setIsAuthentication(false));
    dispatch(clearUser());
    localStorage.removeItem("requestData");
    removeToken();
    navigate(APP_ROUTES.HOME);
  };

  return (
    <nav className="mobile-nav_content text-gray-600 text-xl font-medium">
      <span className="mobile-nav__close" onClick={toggle}>
        <LiaTimesSolid size={24} />
      </span>
      <div className="mobile-nav_container mt-12">
        <ul className="" onClick={toggle}>
          {menu.map((item, idx) => (
            <NavLink to={item.path}>
              <li key={idx} className="py-6">
                {item.title}
              </li>
            </NavLink>
          ))}
        </ul>
      </div>

      <div className="flex items-cente justify-between px-1 mt-5">
        <div className="flex items-center gap-3">
          <img
            src={userObj?.profile_picture ? userObj?.profile_picture : Avi}
            alt="profile picture"
            className="w-[40px] h-[40px] rounded-full object-cover"
          />
          <div className="">
            <p className="text-[#344054] text-sm font-medium">
              {userObj?.first_name} {userObj?.last_name}
            </p>
            <p className="text-[#7A7A7A] text-sm text-ellipsis whitespace-nowrap overflow-hidden break-words">
              {userObj?.email}
            </p>
          </div>
        </div>
        <div onClick={logout} className="cursor-pointer">
          <FiLogOut className="text-[#A3A3A3]" color="red" size={20} />
        </div>
      </div>
    </nav>
  );
};

export default DashboardMobileNav;
