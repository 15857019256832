import { Pagination } from "@/components/Pagination";
import Spinner from "@/components/Spinner";
import { APP_ROUTES } from "@/constants/app-routes";
// import { useInfiniteScroll } from "@/hooks/useInfiniteScroll";
import { IntNotificationPages } from "@/models/Notification";
import { useGetNotifications } from "@/services/notifications";
import { getDeliveryStatusStyles } from "@/utils";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { CiBellOn } from "react-icons/ci";

const Notifications = () => {
  const { getNotifcations } = useGetNotifications();
  const [page, setPage] = useState(1);
  const [data, setData] = useState({} as IntNotificationPages);

  const {
    data: noty,
    isLoading,
    // isFetching,
  } = useQuery({
    queryKey: ["get_notifications", page],
    queryFn: () => getNotifcations(page),
    staleTime: 60000,
  });

  // const hasMore = !!noty?.links.next;

  // const loadMore = () => {
  //   setPage((prevPage) => prevPage + 1);
  // };

  // useInfiniteScroll({
  //   isFetching,
  //   hasMore,
  //   onLoadMore: loadMore,
  // });

  // useEffect(() => {
  //   if (noty) {
  //     setData((prevData) => ({
  //       data:
  //         page === 1 ? noty.data : [...(prevData?.data || []), ...noty.data],
  //       links: noty.links,
  //       meta: noty.meta,
  //     }));
  //   }
  // }, [noty]);

  useEffect(() => {
    if (noty) {
      setData((state) => ({
        ...state,
        data: noty.data,
        links: noty.links,
        meta: noty.meta,
      }));
    }
  }, [noty]);

  return (
    <div>
      {isLoading && <Spinner />}
      {data?.data?.length === 0 && (
        <div className="mt-[60px] flex flex-col justify-center items-center">
          <div className="flex items-center justify-center w-20 h-20 bg-[#DFDFDF40] rounded-full">
            <CiBellOn size={32} />
          </div>
          <p className="text-gray-500 text-sm text-center mt-[30px]">
            Your Notifications will appear here
          </p>
        </div>
      )}

      {data?.data && data?.data?.length > 0 && (
        <div className="mt-4 flex flex-col gap-4">
          {data?.data?.map((noty, idx) => {
            const {
              backgroundColor,
              icon: IconComponent,
              iconColor,
            } = getDeliveryStatusStyles(noty?.message, noty?.status);
            return (
              <div key={idx} className="mt4">
                {/* <p className="text-gray-500 text-[12px] font-medium">Today</p> */}
                <div className="flex gap-4 mt-3">
                  <div
                    className="flex items-center w-[42px] h-[42px] p-3 rounded-full relative"
                    style={{ backgroundColor }}
                  >
                    <IconComponent size={24} color={iconColor} />
                    {/* {noty?.status === "UNREAD" && (
                      <div
                        style={{
                          position: "absolute",
                          backgroundColor: "red",
                          height: 10,
                          width: 10,
                          borderRadius: 9999,
                          top: 0,
                          right: 0,
                        }}
                      ></div>
                    )} */}
                  </div>
                  <div className="flex flex-col gap-3">
                    <p className="text-gray-800 text-sm">{noty?.message}</p>
                    <p className="text-gray-500 text-sm">
                      {noty?.message_body}
                      <p className="">{noty?.estimated_time}</p>
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {data.data && data.data?.length > 0 && data.meta.total > 10 && (
        <nav className="custom-paginator">
          <ul>
            <Pagination
              currentPage={page}
              pages={Math.ceil(data.meta.total / data.meta.per_page)}
              url={APP_ROUTES.NOTIFICATIONS}
              setPages={setPage}
            />
          </ul>
        </nav>
      )}
    </div>
  );
};

export default Notifications;
