import { useEffect } from "react";
import { useScreenContext } from "../context/screenContext";
import { TabsProps } from "./orders";

const Tabs = ({
  tabs,
  setPage,
}: {
  tabs: TabsProps[];
  setPage: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { handleTab, selectedTab, setSelectedTab } = useScreenContext();

  useEffect(() => {
    setSelectedTab(tabs[0].key);
  }, []);

  return (
    <div className="flex items-center gap-10 overflow-x-scroll">
      {tabs?.map((T, idx) => (
        <div
          key={idx}
          onClick={() => {
            handleTab({ tab: T.key });
            setPage(1);
          }}
          className={`bg-brand-25 border border-brand-600 py-2 px-4 rounded-3xl flex gap-2 items-center cursor-pointer ${
            selectedTab !== T.key && "opacity-30"
          }`}
        >
          <p className="text-gray-800 text-sm font-medium">
            {T.title}
          </p>
          <div
            style={{ background: `${T.countBg}` }}
            className={`h-5 w-5 rounded-full text-brand-25 text-sm font-bold flex items-center justify-center flex-shrink-0`}
          >
            {T.count}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Tabs;
