export const PackageCategories = [
  "Computer Accessories",
  "Documents",
  "Electronics",
  "Food",
  "Food Stuff",
  "Jewelry",
  "Phone",
  "Phone Accessories",
  "Cloth",
  "Wigs",
  "Beauty Accessories",
  "Shoes",
  "Laptop",
  "Drinks",
  "Drugs",
  "Others",
];
