import PrimaryButton from "@/components/Button/PrimaryButton";
import Input from "@/components/Input/Input";
import InputPhone from "@/components/Input/InputPhone";
import useScreenSize from "@/hooks/useScreenSize";
import { useProfile } from "@/services/profile";
import { RootState } from "@/stores/store";
import { EditProfileSchema, TEditProfile } from "@/types/validations/profile";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { MdOutlineMail } from "react-icons/md";
import { useSelector } from "react-redux";

const EditProfile = () => {
  const [countryCode, setCountryCode] = useState<string>("+234");
  const { user } = useSelector((state: RootState) => state.user);
  const screenWidth = useScreenSize();
  const { editProfile } = useProfile();
  const userObj = user?.data || user?.user_data;
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<TEditProfile>({
    mode: "onBlur",
    resolver: zodResolver(EditProfileSchema),
    reValidateMode: "onChange",
  });

  const queryClient = useQueryClient();

  const onSubmit: SubmitHandler<TEditProfile> = async (data) => {
    mutate(data);
  };

  const { mutate, isPending } = useMutation({
    mutationFn: editProfile,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["get_user"] });
    },
  });

  useEffect(() => {
    if (user) {
      setValue("first_name", userObj?.first_name || "");
      setValue("phone", userObj?.phone || "");
      setValue("email", userObj?.email ?? "");
      setValue("last_name", userObj?.last_name ?? "");
    }
  }, [user]);

  return (
    <form className="" onSubmit={handleSubmit(onSubmit)}>
      <div
        className={`flex ${
          screenWidth >= 560 ? "flex-row gap-8" : "flex-col gap-3"
        } items-center`}
      >
        <div className={`${screenWidth >= 560 ? "w-[50%]" : "w-full"}`}>
          <Input
            placeholder="enter first name"
            label="First Name"
            isRequired
            {...register("first_name")}
            error={errors.first_name?.message}
          />
        </div>
        <div className={`${screenWidth >= 560 ? "w-[50%]" : "w-full"}`}>
          <Input
            placeholder="enter last name"
            label="Last Name"
            isRequired
            {...register("last_name")}
            error={errors.last_name?.message}
          />
        </div>
      </div>
      <div
        className={`flex ${
          screenWidth >= 560 ? "flex-row gap-8" : "flex-col gap-3"
        } items-center`}
      >
        <div className={`${screenWidth >= 560 ? "w-[50%]" : "w-full"}`}>
          <InputPhone
            id="phone"
            placeholder="9020202020"
            label="Phone Number"
            setSelectedCode={setCountryCode}
            selectedCode={countryCode}
            {...register("phone")}
            error={errors.phone?.message}
            isRequired
          />
        </div>
        <div className={`${screenWidth >= 560 ? "w-[50%]" : "w-full"}`}>
          <Input
            preIcon={<MdOutlineMail size={20} className="text-gray-500" />}
            placeholder="enter email"
            label="Email address"
            isRequired
            {...register("email")}
            error={errors?.email?.message}
          />
        </div>
      </div>
      <div className="flex items-center gap-3 mt-9">
        <PrimaryButton
          isLoading={isPending}
          disabled={isPending}
          type="submit"
          text="Save Changes"
          variant="primary"
        />
        <PrimaryButton
          type="button"
          text="Cancel"
          variant="light"
          className="bg-brand-25 w-[172px]"
        />
      </div>
    </form>
  );
};

export default EditProfile;
