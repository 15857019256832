import React, { InputHTMLAttributes, forwardRef } from "react";
import Label from "../Label/Label";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  error?: string | boolean | string[];
  label?: string;
  isRequired?: boolean;
  preIcon?: React.ReactNode;
  postIcon?: React.ReactNode;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ label, type, isRequired, preIcon, postIcon, error, ...rest }, ref) => {
    return (
      <div className="w-full my-2">
        <Label label={label ?? ""} isRequired={isRequired} />
        <div
          className={`w-full relative ${preIcon ? "pl-10" : "pl-4"} ${
            postIcon ? "pr-10" : "pr-4"
          }
          py-[10px] border ${
            !error ? "border-[#D0D5DD]" : "border-red-500"
          } rounded-lg focus:outline-none focus:border-brand-600 text-gray400 text-sm`}
        >
          <input ref={ref as React.Ref<HTMLInputElement>} {...rest} className="w-full border-none bg-transparent" />

          {preIcon && (
            <div className="absolute top-0 bottom-0 left-[12px] flex items-center justify-start">
              <div className="mr-1">{preIcon}</div>
            </div>
          )}

          {postIcon && (
            <div className="absolute top-[0px] bottom-0 right-[12px] flex items-center justify-start">
              <div className="ml-1">{postIcon}</div>
            </div>
          )}
        </div>
        {error && <small className="px-3 text-red-500">{error}</small>}
      </div>
    );
  }
);

export default Input;
