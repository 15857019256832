import React, { memo } from "react";
import { Libraries, LoadScript } from "@react-google-maps/api";

const GOOGLE_PLACES_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;
const libraries: Libraries = ["places"];

const GoogleMapsProvider = ({ children }: { children: React.ReactNode }) => (
  <LoadScript
    googleMapsApiKey={GOOGLE_PLACES_API_KEY ?? ""}
    libraries={libraries}
  >
    {children}
  </LoadScript>
);

export default memo(GoogleMapsProvider);
