// import { TRequestDetails } from "@/types/validations/request";
import { ApiRequest } from "../ApiNetwork";
import { ITrackOrder, Orders } from "@/models/Order";
import { TCharges } from "@/types/validations/charges";
import Charges from "@/models/Charges";
import { TAnalytics } from "@/models/Analytics";

export const useGetOrders = () => {
  const { request } = ApiRequest();

  const getOrders = async (page = 1, query?: string, id?: string) => {
    try {
      const url =
        query === "ALL"
          ? `/user/orders/getorders${`${
              id ? `?bulk_order_id=${id}&page=${page}` : `?page=${page}`
            }`}`
          : `/user/orders/getorders?status=${query}${`${
              id ? `&bulk_order_id=${id}&page=${page}` : `&page=${page}`
            }`}`;
      const response = await request("GET", {
        url,
        ignoreError: true,
      });

      if (response.status === "success") {
        // dispatch(setUserOrders(response?.data?.data?.orders?.data));
        return response.data?.data;
      } else {
        throw new Error(response.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const getOrder = async (id: string) => {
    try {
      const response = await request("GET", {
        url: `/user/orders/getorders?id=${id}`,
      });
      if (response.status === "success") {
        return response.data.data as Orders;
      } else {
        throw new Error(response.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const requestOrders = async (payload: {
    orders: CombinedDataType[];
    due_date: string | number | undefined;
  }) => {
    try {
      const response = await request("POST", {
        url: "/user/orders/bulk/create",
        payload,
      });
      if (response.status === "success") {
        return response.data as CombinedDataType;
      } else {
        throw new Error(response.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const ordersPayment = async (payload: {
    bulk_order_id: string;
    callback_url: string;
  }) => {
    try {
      const response = await request("POST", {
        url: "/user/orders/bulk/pay",
        payload,
      });
      if (response.status === "success") {
        return response.data.data as { authorization_url: string };
      } else {
        throw new Error(response.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const orderPayment = async (payload: {
    order_id: string;
    callback_url: string;
  }) => {
    try {
      const response = await request("POST", {
        url: "/user/orders/order-checkout",
        payload,
      });
      if (response.status === "success") {
        return response.data.data as { authorization_url: string };
      } else {
        throw new Error(response.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const getCharges = async (payload: { order_details: TCharges[] }) => {
    try {
      const response = await request("POST", {
        url: "/user/orders/bulk/get-charges",
        payload,
      });
      if (response.status === "success") {
        return response.data.data as Charges[];
      } else {
        throw new Error(response.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const getBulkOrders = async (page = 1) => {
    try {
      const response = await request("GET", {
        url: `/user/orders/bulk/list?page=${page}`,
      });
      if (response.status === "success") {
        return response.data.data;
      } else {
        throw new Error(response.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const getAnalytics = async () => {
    try {
      const response = await request("GET", {
        url: `/user/orders/bulk/analytics`,
      });
      if (response.status === "success") {
        return response.data.data as TAnalytics;
      } else {
        throw new Error(response.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const trackOrder = async (payload: { tracking_id: string }) => {
    try {
      const response = await request("GET", {
        url: `/track-order?tracking_id=${payload.tracking_id}`,
      });

      if (response.status === "success") {
        return response.data.data as ITrackOrder;
      } else {
        throw new Error(response.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const payOutstandingPayment = async (payload: { callback_url: string }) => {
    try {
      const response = await request("POST", {
        url: "/user/orders/bulk/pay-outstanding",
        payload,
      });
      if (response.status === "success") {
        return response.data.data as { authorization_url: string };
      } else {
        throw new Error(response.data);
      }
    } catch (error) {
      throw error;
    }
  };

  return {
    getOrders,
    requestOrders,
    getOrder,
    ordersPayment,
    getCharges,
    getBulkOrders,
    getAnalytics,
    orderPayment,
    trackOrder,
    payOutstandingPayment,
  };
};
