import Seo from "@/components/Seo";
import Table from "./components/Table";
import Notifications from "../dashboard/components/notifications";
import Divider from "@/components/Divider";
import SendPackage from "@/components/send-package";
import Ads from "@/components/ads";
import PrimaryButton from "@/components/Button/PrimaryButton";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useGetOrders } from "@/services/order";

const BulkOrder = () => {
  const queryClient = useQueryClient();
  const { payOutstandingPayment } = useGetOrders();

  const BASE_URL = process.env.REACT_APP_WEBSITE_URL;

  const { mutate: paymentFn, isPending } = useMutation({
    mutationFn: payOutstandingPayment,
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ["get_orders"] });
      const redirectUrl = response?.authorization_url;
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    },
  });

  return (
    <div className="flex w-full min-hscreen">
      <Seo title="Point2 | Bulk Order" />
      <div className="border-r border-r-gray-200 min-835:w-[70%] w-full py-5 overflow-y-scroll h-screen px-8">
        <div className="mb-3">
          <PrimaryButton
            isLoading={isPending}
            variant="primary"
            text="Pay all"
            size="sm"
            onClick={() =>
              paymentFn({
                callback_url: BASE_URL ?? "",
              })
            }
          />
        </div>
        <div className="table-responsive">
          <Table />
        </div>
      </div>
      <div className="w-[30%] wfull min-hscreen sticky top-0 left-0 min-835:block hidden">
        <div className="h-screen overflow-y-scroll overflow-hidden">
          <Notifications />
          <div className="px-4">
            <Divider />
            <SendPackage />
            <Ads />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkOrder;
