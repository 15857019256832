import OrderDetails from "./components/order-details";
import Orders from "./components/orders";
import ScreenContextProvider from "./context/screenContext";

const Activity = () => {
  return (
    <ScreenContextProvider>
      <div className="flex w-full min-hscreen">
        <div className="border-r border-r-gray-200 min-835:w-[70%] w-full py-5 overflow-y-scroll h-screen px-8">
          <Orders />
        </div>
        <div className="w-[30%] wfull min-hscreen sticky top-0 left-0 min-835:block hidden">
          <div className="px-4 h-screen overflow-y-scroll overflow-hidden">
            <OrderDetails />
          </div>
        </div>
      </div>
    </ScreenContextProvider>
  );
};

export default Activity;
