import React, { useMemo, useState } from "react";
import { BsShieldCheck } from "react-icons/bs";
import { GoPerson } from "react-icons/go";
import EditProfile from "./edit-profile";
import useScreenSize from "@/hooks/useScreenSize";
import Support from "./support";
import { useNavigate } from "react-router-dom";

const ProfileTabs = () => {
  const screenWidth = useScreenSize();
  const navigate = useNavigate();
  const [step, setStep] = useState<number | null>(null);
  const handleTabChange = (e: number | string) => setStep(e as number);

  const Tabs: { icon: React.ReactNode; title: string; link?: string }[] =
    useMemo(() => {
      return [
        {
          icon: <GoPerson size={24} />,
          title: "Edit Details",
        },
        {
          icon: <BsShieldCheck size={24} />,
          title: "Privacy & Policy",
          // link: "https://point2.ng/privacy-policy",
        },
        {
          icon: <BsShieldCheck size={24} />,
          title: "Help & Support",
        },
      ];
    }, []);

  const componentsToRender = {
    0: <EditProfile />,
    2: <Support />,
  };

  return (
    <div className="mt-7">
      <p className="text-gray-700 text-base">Edit Profile</p>
      <div
        className={`mt-5 flex ${
          screenWidth >= 540 ? "flex-row" : "flex-col"
        } gap-6`}
      >
        {Tabs.map((tab, idx) => (
          <div
            onClick={() =>
              tab.link ? navigate(tab.link) : handleTabChange(idx)
            }
            key={idx}
            className={`${
              step === idx
                ? "bg-brand-600 text-white"
                : "bg-gray-50 text-gray-500"
            } p-4 rounded-lg flex flex-col gap-4 w-full cursor-pointer`}
          >
            {tab.icon}
            <p className="text-gray500 font-medium text-base">{tab.title}</p>
          </div>
        ))}
      </div>
      <div className="mt-9">
        {componentsToRender[step as keyof typeof componentsToRender]}
      </div>
    </div>
  );
};

export default ProfileTabs;
