import Ads from "@/components/ads";
import SendPackage from "@/components/send-package";
import Analytics from "./component/analytics";
import ProfileTabs from "../profile/component/profile-tabs";
import ProfileHeader from "../profile/component/profile-header";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RootState } from "@/stores/store";
import { useSelector } from "react-redux";
import Seo from "@/components/Seo";

const More = () => {
  const { user } = useSelector((state: RootState) => state?.user);
  const userObj = user?.user_data || user?.data;
  const navigate = useNavigate();
  useEffect(() => {
    if (userObj?.type === "USER") {
      navigate(-1);
    }
  }, [userObj]);

  return (
    <div className="flex w-full min-hscreen">
      <Seo title="Point2 | User Analytics and more" />
      <div className="border-r border-r-gray-200 min-835:w-[70%] w-full py-5 overflow-y-scroll h-screen px-8">
        <ProfileHeader />
        <Analytics />
        <ProfileTabs />
      </div>
      <div className="w-[30%] wfull min-hscreen sticky top-0 left-0 min-835:block hidden">
        <div className="px-4 h-screen overflow-y-scroll overflow-hidden">
          <SendPackage />
          <Ads />
        </div>
      </div>
    </div>
  );
};

export default More;
