import Image1 from "@/assets/images/business-owner.png";
import Image2 from "@/assets/images/rider.png";
import PrimaryButton from "@/components/Button/PrimaryButton";

const WhoCanUse = () => {
  return (
    <section className="mt-24 flex flex-col items-center">
      <div className="w-full max-w-[940px] px-3">
        <h1 className="text-[30px] md:text-[36px] font-bold text-center text-gray-800">
          Who can use Point2 Logistics
        </h1>
        <div className="flex flex-col md:flex-row md:items-center gap-[30px] md:gap-[44px] mt-14">
          <div className="w-full max-w-[584px] rounded-2xl">
            <img src={Image1} alt="business owner" className="w-full" />
          </div>
          <div className="justify-start">
            <h2 className="text-gray-900 text-[30px] md:text-[36px] font-bold">
              Business Owner
            </h2>
            <p className="text-gray-700 text-base w-full max-w-[312px] mt-3 mb-7">
              Are you a business owner that sells online and needs delivery
              services, use our service for the best experience
            </p>
            <PrimaryButton text="Request a Demo" variant="primary" />
          </div>
        </div>
        <div className="flex flex-col md:flex-row-reverse md:items-center gap-[30px] md:gap-[44px] mt-14">
          <div className="w-full max-w-[584px] rounded-2xl">
            <img src={Image2} alt="business owner" className="w-full" />
          </div>
          <div className="">
            <h2 className="text-gray-900 text-[30px] md:text-[36px] font-bold">
              Are you a Rider?
            </h2>
            <p className="text-gray-700 text-base w-full max-w-[312px] mt-3 mb-7">
              Want to be part of our happy and independent couriers community ?
            </p>
            <PrimaryButton text="Join Us" variant="primary" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoCanUse;
