import { Outlet, useNavigate } from "react-router-dom";
import PatternBg from "@/assets/icons/login-pattern.png";
import DeliveryMan from "@/assets/images/delivery-man.png";
import { useEffect } from "react";
import { APP_ROUTES } from "@/constants/app-routes";
import { getToken } from "@/helpers/authHelpers";
import Logo from "@/assets/images/logo.png";

const AuthLayout = () => {
  const token = getToken();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate(APP_ROUTES.HOME);
    }
  }, [token]);

  return (
    <div className="bg-brand-25 bg-no-repeat bg-cover h-auto w-full relative z-10">
      <div
        className="absolute inset-0 -z-10 bg-cover bg-center bg-no-repeat w-full max-w-[762px]"
        style={{ backgroundImage: `url(${PatternBg})` }}
      ></div>
      <div className="w-full h-full flex flex-col-reverse lg:flex-row lg:justify-start lg:items-start">
        <div className="w-full lg:max-w-[50%] flex flex-col items-center mt-28 px-5">
          <div className="flex flex-col gap-3">
            <h3 className="text-gray-900 font-medium text-[36px] max-w-[469px]">
              Order, Track and Manage Deliveries
            </h3>
            <p className="text-gray-500 text-xl">
              Continue to your Point2 dashboard
            </p>
            <img src={DeliveryMan} alt="delivery man" />
          </div>
        </div>
        <div className="w-full lg:max-w-[50%] flex justify-center items-center flex-col h-screen bg-white z-20">
          <div className="w-[90%] max-w-[342px] mx-auto my-8 lg:my-24 flex itemscenter flex-col">
            <img
              src={Logo}
              alt=""
              className="w-[90px] h-[90px] cursor-pointer"
              onClick={() => navigate("/")}
            />
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
