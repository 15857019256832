import Divider from "@/components/Divider";
import Notifications from "./components/notifications";
import RecentOrder from "./components/recent-order";
import TrackingInput from "./components/tracking-input";
// import UserLocation from "./components/user-location";
import Ads from "@/components/ads";
import SendPackage from "@/components/send-package";
import Seo from "@/components/Seo";

const Dashboard = () => {
  return (
    <div className="flex w-full min-hscreen">
      <Seo />
      <div className="border-r border-r-gray-200 min-835:w-[70%] w-full py-5 overflow-y-scroll h-screen px-8">
        {/* <UserLocation /> */}
        <TrackingInput />
        <div className="min-835:hidden block">
          <Ads />
        </div>
        <RecentOrder />
      </div>
      <div className="w-[30%] wfull min-hscreen sticky top-0 left-0 min-835:block hidden">
        <div className="h-screen overflow-y-scroll overflow-hidden">
          <Notifications />
          <div className="px-4">
            <Divider />
            <SendPackage />
            <Ads />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
