import { useState } from "react";
import Box from "@/assets/svgs/box.svg";
import Scan from "@/assets/svgs/scan.svg";
import TrackingDetails from "@/components/TrackingDetails";
import { useDisclosure } from "@/hooks/useDisclosure";
import useScreenSize from "@/hooks/useScreenSize";
import { APP_ROUTES } from "@/constants/app-routes";
import { useNavigate } from "react-router-dom";
import { Toast } from "@/config/toast";

const TrackingInput = () => {
  const [input, setInput] = useState("");
  const navigate = useNavigate();
  const screenWidth = useScreenSize();
  const {
    isOpen: openModal,
    onClose: onOpenModalClose,
    onOpen: onOpenModalOpen,
  } = useDisclosure();

  return (
    <>
      <div className="w-full rounded-lg bg-brand-25 px-3 py-4 my-5 flex flex-col min-1105:flex-row gap-2 min-1105:gap-0 min-1105:items-center">
        <div className="flex-1">
          <p className="text-gray-800 font-bold text-sm">Track your package</p>
          <p className="text-gray-800 text-sm">
            Please enter your Package Tracking ID
          </p>
        </div>
        <div className="flex items-center bg-white rounded-[10px] h-[42px] w-full max-w-[455px]">
          <div className="pl-3">
            <img src={Box} alt="" width={24.1} height={28.12} />
          </div>
          <input
            className="text-sm text-gray-400 flex-1 pl-3"
            placeholder="Enter Tracking ID here"
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
          <div
            onClick={() =>
              input
                ? screenWidth <= 925
                  ? navigate(APP_ROUTES.TRACK, { state: { trackingId: input } })
                  : onOpenModalOpen()
                : Toast.error("Please enter a tracking id")
            }
            className="flex items-center justify-center h-[42px] w-[42px] rounded-[10px] bg-brand-600 ml-3 cursor-pointer"
          >
            <img src={Scan} alt="" width={24} height={24} />
          </div>
        </div>
      </div>
      {openModal && (
        <TrackingDetails
          onClose={() => onOpenModalClose()}
          trackingId={input}
        />
      )}
    </>
  );
};

export default TrackingInput;
