import { useDispatch } from "react-redux";
import { ApiRequest } from "../ApiNetwork";
import { setIsAuthentication, setUser } from "@/stores/reducers/user-reducers";
import { User } from "@/models/User";
import { TEditProfile } from "@/types/validations/profile";
import { setSuccessMessages } from "@/stores/reducers/app-reducers";

export const useProfile = () => {
  const { request } = ApiRequest();
  const dispatch = useDispatch();

  const fetchUser = async () => {
    try {
      const response = await request("GET", {
        url: "/profile/details",
        ignoreError: true,
      });
      if (response.status === "success") {
        const returnRes = response.data as User;
        dispatch(setIsAuthentication(true));
        dispatch(setUser(returnRes));
        return returnRes;
      } else {
        throw new Error(response.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const uploadImage = async (file: File | null) => {
    try {
      let imagefile: any = new FormData();
      imagefile.append("profile_picture", file);
      const response = await request("POST", {
        url: "/profile/upload-photo",
        payload: imagefile,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === "success") {
        return response.data;
      } else {
        throw new Error(response.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const editProfile = async (payload: TEditProfile) => {
    try {
      const response = await request("POST", {
        url: "/profile/edit-details",
        payload,
      });
      if (response.status === "success") {
        const returnRes = response.data;
        dispatch(setSuccessMessages([response?.data?.message]));
        return returnRes;
      } else {
        throw new Error(response.data);
      }
    } catch (error) {
      throw error;
    }
  };

  return {
    fetchUser,
    uploadImage,
    editProfile,
  };
};
