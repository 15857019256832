import { useSelector } from "react-redux";
import BreadCrumb from "../BreadCrumb";
// import { IoIosArrowDown } from "react-icons/io";
import { RootState } from "@/stores/store";
import { useDisclosure } from "@/hooks/useDisclosure";
import DashboardMobileNav from "@/layouts/DashboardMobileNav";
import Bars from "@/assets/svgs/bar.svg";
import { Link } from "react-router-dom";
import Logo from "@/assets/images/logo.png";

interface TNavProps {
  title: string;
}

const DashboardNav = (props: TNavProps) => {
  const { user } = useSelector((state: RootState) => state.user);
  const userObj = user?.user_data || user?.data;
  const {
    onClose: onCloseMobileNav,
    onOpen: onOpenMobileNav,
    isOpen: isOpenMobileNav,
  } = useDisclosure();

  return (
    <div className="h-[75px] w-full px-8 flex items-center justify-between bg-gray-25 sticky top-0 left-0 right-0 z-10">
      <div className="min-1105:block hidden">
        <BreadCrumb title={props?.title} />
      </div>

      <div className="sticky top-0 right-0 left-0 z-50">
        <div
          className="min-1105:hidden cursor-pointer"
          onClick={onOpenMobileNav}
        >
          <img src={Bars} alt="bars" />
        </div>
        <div
          className={`${
            isOpenMobileNav ? "left0" : "left-100"
          } min-1105:hidden block mobile-wrapper`}
        >
          <div className="mobile-overlay" onClick={onCloseMobileNav}></div>
          <DashboardMobileNav toggle={onCloseMobileNav} />
        </div>
      </div>
      <Link to="/" className="min-1105:hidden">
        <img src={Logo} alt="" className="w-[50px] h-[50px]" />
      </Link>
      <div className="flex items-center gap-2">
        <div className="bg-gray-100 py-1 px-4 rounded-full text-gray-700 text-sm">
          <p>{userObj?.type === "USER" ? "Personal" : "Corporate"}</p>
        </div>
        <div className="bg-brand-50 h-10 w-10 rounded-full text-brand-600 text-base font-medium flex items-center justify-center">
          <p>
            {userObj?.first_name.charAt(0)} {userObj?.last_name.charAt(0)}
          </p>
        </div>
        {/* <IoIosArrowDown size={16} /> */}
      </div>
    </div>
  );
};

export default DashboardNav;
