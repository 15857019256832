import PrimaryButton from "@/components/Button/PrimaryButton";
// import Input from "@/components/Input/Input";
import InputPhone from "@/components/Input/InputPhone";
import Seo from "@/components/Seo";
import { useAuthServices } from "@/services/auth";
import {
  ForgotPasswordSchema,
  TForgotPassword,
} from "@/types/validations/auth";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
// import { MdOutlineMail } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { authForgotPassword } = useAuthServices();
  const [countryCode, setCountryCode] = useState<string>("+234");
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<TForgotPassword>({
    mode: "onBlur",
    resolver: zodResolver(ForgotPasswordSchema),
    reValidateMode: "onChange",
  });

  const onSubmit: SubmitHandler<TForgotPassword> = async (data) => {
    mutate(data);
  };

  const { mutate, isPending } = useMutation({
    mutationFn: authForgotPassword,
    onSuccess: () => {
      //   navigate(APP_ROUTES.VERIFY_OTP);
    },
  });

  return (
    <section className="w-full max-w-[488px]">
      <Seo title="Point2 | Forget password" />
      <h1 className="text-gray-700 text-[30px] font-medium">Forgot Password</h1>
      <p className="text-gray-900 text-sm font-medium mt-1">
        An OTP to reset password will be sent to entered email
      </p>
      <div className="flex flex-col items-center justify-center">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="mt-10 w-full max-w-[425px]"
          action=""
        >
          {/* <Input
            //   preIcon={<MdOutlineMail size={20} className="text-gray-500" />}
            isRequired
            label="Email address"
            placeholder="enter email address"
            {...register("email")}
            error={errors.email?.message}
          /> */}
          <InputPhone
            id="phone"
            placeholder="9020202020"
            label="Phone Number"
            setSelectedCode={setCountryCode}
            selectedCode={countryCode}
            {...register("phone")}
            error={errors.phone?.message}
            isRequired
          />
          <div className="mt-10">
            <PrimaryButton
              text="Next"
              variant="primary"
              className="w-full"
              isLoading={isPending}
              disabled={isPending}
            />
          </div>
          <div
            onClick={() => navigate(-1)}
            className="text-[#404040] font-medium text-sm text-center mt-5 cursor-pointer"
          >
            Go back
          </div>
        </form>
      </div>
    </section>
  );
};

export default ForgotPassword;
