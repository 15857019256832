import { BsTelephone } from "react-icons/bs";
import { PiChatsBold } from "react-icons/pi";
import { Link } from "react-router-dom";

const Support = () => {
  return (
    <div className="flex flex-col gap-y-8">
      <a href="tel:+2347011110777" className="flex items-center gap-4">
        <div className="w-[48px] h-[48px] flex items-center justify-center rounded-full bg-brand-25">
          <BsTelephone size={24} />
        </div>
        <p className="text-gray-500 font-medium text-lg">Contact Us</p>
      </a>
      <Link to="/#faqs" className="flex items-center gap-4">
        <div className="w-[48px] h-[48px] flex items-center justify-center rounded-full bg-brand-25">
          <PiChatsBold className="text-gray-500" size={24} />
        </div>
        <p className="text-gray-500 font-medium text-lg">FAQs</p>
      </Link>
    </div>
  );
};

export default Support;
