import Tabs from "./Tabs";
import EmptyBox from "@/assets/svgs/emptyBox.svg";
import { Orders, OrdersData } from "@/models/Order";
import Box from "@/assets/svgs/box2.svg";
import { GoDotFill } from "react-icons/go";
import { FaRegCircleCheck } from "react-icons/fa6";
import { useScreenContext } from "../context/screenContext";
import { useGetOrders } from "@/services/order";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import Spinner from "@/components/Spinner";
import { getStatus, getStatusColor } from "@/utils";
// import { useInfiniteScroll } from "@/hooks/useInfiniteScroll";
import { Pagination } from "@/components/Pagination";
import { APP_ROUTES } from "@/constants/app-routes";
import { useLocation, useNavigate } from "react-router-dom";
import useScreenSize from "@/hooks/useScreenSize";

export interface TabsProps {
  title: string;
  key: string;
  count?: string | number;
  countBg?: string;
}

const OrdersList = () => {
  const { setOrderId, orderId, selectedTab } = useScreenContext();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const { state } = useLocation();
  const [allOrders, setAllOrders] = useState({} as OrdersData);
  const screenWidth = useScreenSize();

  const { getOrders } = useGetOrders();

  const {
    data: orders,
    isLoading,
    // isFetching,
  } = useQuery({
    queryKey: ["get_orders", page, selectedTab, state?.bulk_order_id],
    queryFn: () => getOrders(page, selectedTab, state?.bulk_order_id),
  });

  // const hasMore = !!orders?.links.next;

  // const loadMore = () => {
  //   setPage((prevPage) => prevPage + 1);
  // };

  // useInfiniteScroll({
  //   isFetching,
  //   hasMore,
  //   onLoadMore: loadMore,
  // });

  // useEffect(() => {
  //   if (orders) {
  //     setAllOrders((prevData) => ({
  //       data:
  //         page === 1
  //           ? orders.data
  //           : [...(prevData?.data || []), ...orders.data],
  //       links: orders.links,
  //       meta: orders.meta,
  //     }));
  //   }
  // }, [orders]);

  useEffect(() => {
    if (orders) {
      setAllOrders((state) => ({
        ...state,
        data: orders.orders?.data,
        links: orders.orders?.links,
        meta: orders.orders?.meta,
      }));
    }
  }, [orders]);

  const tabs = useMemo(() => {
    const tabs: TabsProps[] = [
      {
        title: "All",
        key: "ALL",
        count: orders?.ordersListCount ?? 0,
        countBg: "#444CE7",
      },
      {
        title: "Pending",
        key: "PENDING",
        count: orders?.ordersPending ?? 0,
        countBg: "#444CE7",
      },
      {
        title: "Delivered",
        key: "DELIVERED",
        count: orders?.ordersComplete ?? 0,
        countBg: "#27AE60",
      },
      {
        title: "In Transit",
        key: "INTRANSIT",
        count:
          orders?.orders?.data.filter(
            (datum: Orders) => datum?.status === "INTRANSIT"
          )?.length ?? 0,
        countBg: "#F2C94C",
      },
      {
        title: "Cancelled",
        key: "CANCELLED",
        count:
          orders?.orders?.data.filter(
            (datum: Orders) => datum?.status === "CANCELLED"
          )?.length ?? 0,
        countBg: "#EB5757",
      },
    ];
    return tabs;
  }, [orders]);

  return (
    <div>
      <Tabs tabs={tabs} setPage={setPage} />
      <div className="mt-8">
        <p className="text-gray-600 font-medium text-lg">Orders</p>

        {isLoading && <Spinner />}
        {allOrders?.data?.length === 0 && (
          <div className="mt-[60px]">
            <div className="flex items-center justify-center">
              <img src={EmptyBox} alt="" className="w-[150px] h-[150px]" />
            </div>
            <p className="text-gray-800 text-sm text-center">
              Start sending packages <br /> to see activity here
            </p>
          </div>
        )}

        {allOrders?.data && allOrders?.data?.length > 0 && (
          <div className="flex flex-col gap-4">
            {allOrders?.data?.map((order: Orders) => (
              <div
                key={order.id}
                onClick={() =>
                  screenWidth >= 836
                    ? setOrderId(order?.id)
                    : navigate(APP_ROUTES.ORDER_DETAILS, {
                        state: { orderId: order?.id },
                      })
                }
                className={`${
                  orderId === order?.id ? "border-brand-600" : "border-gray-200"
                } border-[0.5px] rounded-2xl w-full h[138px] mt-3 p-6`}
              >
                <div className="flex flex-col sm:flex-row justify-between gap-3 sm:gap-0 sm:items-center">
                  <div className="flex gap-3">
                    <img src={Box} alt="box" />
                    <div>
                      <p className="text-gray-700 font-bold text-sm">
                        {order?.package_name}
                      </p>
                      <p className="text-gray-800 text-sm font-normal">
                        Tracking ID: {order?.tracking_id ?? "Unavailable"}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-3">
                    <p className="text-gray-600 font-bold text-sm">
                      Status: {getStatus(order?.status)}
                    </p>
                    <FaRegCircleCheck color={getStatusColor(order?.status)} />
                  </div>
                </div>
                <div className="flex flex-col md:flex-row gap-3 md:gap-0 mt-6">
                  <div className="flex-1 flex gap-1">
                    <GoDotFill className="text-brand-100" size={20} />
                    <div>
                      <p className="text-gray-600 text-xs font-medium">From</p>
                      <p className="text-gray-700 text-sm font-medium">
                        {order?.pickup_location}
                      </p>
                    </div>
                  </div>
                  <div className="flex-1 flex">
                    <GoDotFill className="text-green-primary" size={20} />
                    <div>
                      <p className="text-gray-600 text-xs font-medium">
                        Shipped to
                      </p>
                      <p className="text-gray-700 text-sm font-medium">
                        {order?.delivery_point_location}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {allOrders.data &&
        allOrders.data?.length > 0 &&
        allOrders.meta.total > 10 && (
          <nav className="custom-paginator">
            <ul>
              <Pagination
                currentPage={page}
                pages={Math.ceil(
                  allOrders.meta.total / allOrders.meta.per_page
                )}
                url={APP_ROUTES.ACTIVITY}
                setPages={setPage}
              />
            </ul>
          </nav>
        )}
    </div>
  );
};

export default OrdersList;
