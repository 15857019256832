// import { APP_ROUTES } from "@/constants/app-routes";
import useScreenSize from "@/hooks/useScreenSize";
import { useGetOrders } from "@/services/order";
import { amountFormat } from "@/utils";
import { useQuery } from "@tanstack/react-query";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const Analytics = () => {
  const { getAnalytics } = useGetOrders();
  const screenWidth = useScreenSize();

  const { data } = useQuery({
    queryKey: ["get_analytics"],
    queryFn: () => getAnalytics(),
  });

  return (
    <div className="mt-7">
      <p className="text-gray-700 text-base">Analytics</p>
      <div
        className={`mt-5 flex ${
          screenWidth >= 600 ? "flex-row" : "flex-col"
        } gap-6`}
      >
        <Button
          count={data?.bulk_orders_count.toString() ?? ""}
          title="Total Orders"
          tag="This month"
          otherInfo={false}
        />
        <Button
          count={amountFormat(data?.amount_spent.toString() ?? "")}
          title="Total Paid Order"
          tag="This month"
          otherInfo={false}
        />
        <Button
          count={amountFormat(data?.upcoming_payment.toString() ?? "")}
          title="Total Pending Payment"
          tag="Due in 5days"
          tagBg="#FF9500"
          otherInfo={false}
          // otherInfoTitle="Make Payment"
          // infoLink={APP_ROUTES.INVOICE}
        />
      </div>
    </div>
  );
};

const Button = ({
  count,
  title,
  tag,
  tagBg,
  otherInfo,
  otherInfoTitle,
  infoLink,
}: {
  count: number | string;
  title: string;
  tag: string;
  tagBg?: string;
  otherInfo?: boolean;
  otherInfoTitle?: string;
  infoLink?: string;
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={
        "bg-gray-50 py-4 rounded-lg flex flex-col gap4 w-full border border-gray-100"
      }
    >
      <p className="text-gray-500 text-[24px] font-medium px-2 py-2">{count}</p>
      <div className="flex items-center gap-2 px-2">
        <p className="text-gray-500 font-medium text-sm">{title}</p>
        {/* <div
          style={{ background: tagBg }}
          className="bg-green-primary p-1 rounded-full text-white text-[10px] font-medium"
        >
          {tag}
        </div> */}
      </div>
      {otherInfo && (
        <div
          className="bg-brand-600 p-2 rounded-b-lg flex justify-between items-center mt-1 cursor-pointer"
          onClick={() => navigate(infoLink ?? "")}
        >
          <p className="text-[12px] font-bold text-white">{otherInfoTitle}</p>
          <MdKeyboardArrowRight className="text-white" />
        </div>
      )}
    </div>
  );
};

export default Analytics;
