import { GoDotFill } from "react-icons/go";
import Divider from "./Divider";
import { FaRegCircleCheck } from "react-icons/fa6";
import SideModal from "./Modal/SideModal";
import Box from "@/assets/svgs/box.svg";
import { useGetOrders } from "@/services/order";
import { useQuery } from "@tanstack/react-query";
import Spinner from "./Spinner";
import useScreenSize from "@/hooks/useScreenSize";
import { amountFormat } from "@/utils";

interface DetailsProps {
  onClose: () => void;
  trackingId: string;
}

const TrackingDetails = ({ onClose, trackingId }: DetailsProps) => {
  const { trackOrder } = useGetOrders();
  const screenWidth = useScreenSize();

  const { data, isLoading } = useQuery({
    queryKey: ["track_order"],
    queryFn: () => trackOrder({ tracking_id: trackingId }),
    staleTime: 60000,
  });

  const statusToStep: { [key: string]: string[] } = {
    order: ["pending", "assignedtorider", "accepted", "canceled"],
    pickedup: ["pickedup"],
    intransit: ["intransit"],
    delivered: ["delivered"],
  };

  const steps = [
    { id: "order", label: "Order" },
    { id: "pickedup", label: "Picked up" },
    { id: "intransit", label: "In Transit" },
    { id: "delivered", label: "Delivered" },
  ];

  const getCurrentStepIndex = (status: string) => {
    return steps.findIndex((step) =>
      statusToStep[step.id]?.includes(status.toLowerCase())
    );
  };

  const currentStepIndex = getCurrentStepIndex(data?.status ?? "");

  return (
    <SideModal
      onClose={onClose}
      title="Track Package"
      subTitle={`Tracking ID: ${trackingId}`}
      className="w-[897px]"
    >
      {isLoading && <Spinner />}
      <div className="my-6">
        <Divider />
        <div className="mt-5">
          <h2 className="text-gray-800 font-bold text-xl">Status</h2>
          <div
            className={`flex justify-between ${
              screenWidth >= 835 ? "gap-0" : "gap-3"
            } items-center mt-7 mb-10`}
          >
            {steps.map((step, index) => (
              <div key={step.id} className="flex-1">
                {/* Progress Line */}
                <div className="w-full max-w-[182px] h-1 bg-gray-300 relative">
                  {/* Highlighted portion */}
                  {index <= currentStepIndex && (
                    <div className="absolute top-0 left-0 w-full h-1 bg-green-primary" />
                  )}
                  {index === currentStepIndex && (
                    <div
                      className="absolute top-0 left-0 h-1 bg-green-primary"
                      style={{
                        width: `${
                          data?.status.toLowerCase() === "intransit" &&
                          index === currentStepIndex
                            ? "70%"
                            : "100%"
                        }`,
                      }}
                    />
                  )}
                </div>

                {/* Status Icon and Label */}
                <div className="flex items-center gap-2 mt-2">
                  {index < currentStepIndex ? (
                    // Completed Step
                    <FaRegCircleCheck
                      className="text-green-primary"
                      size={16}
                    />
                  ) : index === currentStepIndex ? (
                    // Current Step
                    <div className="relative w-4 h-4 rounded-full border-[1.5px] border-gray-300">
                      <div className="absolute top-[-1.5px] right-[-1.5px] w-[9px] h-[9px] border-t-[2px] border-r-[2px] border-blue-500 rounded-tr-full"></div>
                    </div>
                  ) : (
                    // Future Step
                    <div className="border-[1.5px] border-gray-300 w-4 h-4 rounded-full" />
                  )}
                  <p className="text-gray-600 text-sm font-medium">
                    {step.label}
                  </p>
                </div>
              </div>
            ))}
          </div>

          <Divider />
          <div className="mt-5">
            <p className="text-gray-800 text-sm">Package Details</p>
            <div className="my-7">
              <div className="flex gap-[17px] items-center">
                <div className="bg-brand-25 h-[42px] w-[42px] rounded-full flex justify-center items-center">
                  <img src={Box} alt="box" />
                </div>
                <p className="text-gray-700 font-bold text-sm">
                  {data?.package_name}
                </p>
              </div>
              <div className="flex flex-wrap gap-x-32 gap-y-6 mt-7">
                <div>
                  <p className="text-gray-600 text-[12px] font-medium">
                    Recipient Name
                  </p>
                  <p className="font-bold text-sm text-gray-700">
                    {data?.recepient_name}
                  </p>
                </div>
                <div>
                  <p className="text-gray-600 text-[12px] font-medium">
                    Rider’s Name
                  </p>
                  <p className="font-bold text-sm text-gray-700">
                    {data?.rider_name ?? "Unavailble"}
                  </p>
                </div>
                <div>
                  <p className="text-gray-600 text-[12px] font-medium">
                    Reciever
                  </p>
                  <p className="font-bold text-sm text-gray-700">
                    {data?.payee}
                  </p>
                </div>
                <div>
                  <p className="text-gray-600 text-[12px] font-medium">
                    Payment Status
                  </p>
                  <p
                    className={`font-bold text-sm ${
                      data?.payment_status === "APPROVED"
                        ? "text-green-primary"
                        : "text-orange-primary"
                    }`}
                  >
                    {data?.payment_status}
                  </p>
                </div>
                <div>
                  <p className="text-gray-600 text-[12px] font-medium">
                    Delivery type
                  </p>
                  <p className="font-bold text-sm text-gray-700">
                    {data?.delivery_type === "STANDARD_DELIVERY"
                      ? "Standard Delivery"
                      : data?.delivery_type === "EXPRESS_DELIVERY"
                      ? "Express Delivery"
                      : ""}
                  </p>
                </div>
                <div>
                  <p className="text-gray-600 text-[12px] font-medium">
                    Amount Payable (N)
                  </p>
                  <p className="font-bold text-sm text-gray-700">
                    {amountFormat(data?.amount.toString() ?? "")}
                  </p>
                </div>
              </div>
              <div className="bg-gray-50 mt-7 p-4 rounded-2xl flex flex-col gap-10">
                <div className="flex-1 flex gap-1">
                  <GoDotFill className="text-brand-100" size={20} />
                  <div>
                    <p className="text-gray-600 text-xs font-medium">From</p>
                    <p className="text-gray-700 text-sm font-medium">
                      {data?.pickup_location}
                    </p>
                  </div>
                </div>
                <div className="flex-1 flex gap-1">
                  <GoDotFill className="text-green-primary" size={20} />
                  <div>
                    <p className="text-gray-600 text-xs font-medium">
                      Shipped to
                    </p>
                    <p className="text-greay-700 text-sm font-medium">
                      {data?.delivery_point_location}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SideModal>
  );
};

export default TrackingDetails;
