import { IntNotificationPages } from "@/models/Notification";
import { ApiRequest } from "../ApiNetwork";

export const useGetNotifications = () => {
  const { request } = ApiRequest();

  const getNotifcations = async (page?: number) => {
    const response = await request("GET", {
      url: `/notifications?page=${page}`,
    });

    if (response.status === "success") {
      return response.data?.data as IntNotificationPages;
    }
  };

  return {
    getNotifcations,
  };
};
