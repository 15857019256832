import Accordion from "@/components/Accordion/Accordion";
import { useState } from "react";

const Faqs = () => {
  const [expandedIndexes, setExpandedIndexes] = useState<number[]>([]);

  const toggleDropdown = (index: number) => {
    setExpandedIndexes((prevState) =>
      prevState.includes(index)
        ? prevState.filter((i) => i !== index)
        : [...prevState, index]
    );
  };

  const faqItems = [
    {
      title: "What are my payment options?",
      content: "Payment options details...",
    },
    {
      title: "How do I make Payment?",
      content: "How to make payment details...",
    },
    {
      title:
        "Will my package be delivered to my final destination anywhere in Nigeria?",
      content: "Package delivery details...",
    },
    {
      title: "Am I Charged for Cancelling Order?",
      content: "Cancellation details...",
    },
    {
      title: "Is there a minimum delivery charge?",
      content: "Delivery charge details...",
    },
  ];

  return (
    <section id="faqs" className="flex flex-col items-center mt-10">
      <div className="width-container">
        <div className="flex flex-col md:flex-row justify-between mt-2">
          <div className="">
            <h2 className="text-gray-900 text-[30px] md:text-[36px] font-bold max-w-[424px]">
              Frequently Asked Questions (FAQs)
            </h2>
            <p className="text-gray-700 text-sm md:text-base max-w-[312px] mt-3 mb-7">
              Still have questions? Contact us via our support center or email
              us at <a href="mailto:support@point2.ng">support@point2.ng</a>
            </p>
          </div>

          <div className="w-full max-w-[717px]">
            {faqItems.map((item, index) => (
              <Accordion
                key={index}
                title={item.title}
                isCollapsed={expandedIndexes.includes(index)} // Check if the index is expanded
                toggleSection={() => toggleDropdown(index)} // Toggle the specific section by index
              >
                <div className="bg-brand-25 rounded-xl py-2 px-4">
                  <p className="text-sm text-gray-500">{item.content}</p>
                </div>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faqs;
